import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Animated from 'components/generals/AnimatedComponent';
import narvalLogo from 'assets/images/logos/logo-white-blue.png';
import { Link } from 'react-router-dom';
import { GrNext } from 'react-icons/gr';

export default function ESGPage() {
    const { t } = useTranslation();
    const location = useLocation();

    useEffect(() => {
        const hash = location.hash;
        if (hash) {
            setTimeout(() => {
                const element = document.querySelector(hash);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }, 0);
        }
    }, [location]);

    return (
        <>
            <main
                className="nv__services nv__services--full nv__services--mt nv__section "
                id="services"
            >
                <section className="nv__section__container">
                    <Animated animation={'animate__fadeInUp'}>
                        <h1 className="nv__section__title nv__section__title--has-description text-center">
                            {t('esg.title')}
                        </h1>
                    </Animated>

                    {/* <Animated animation={'animate__fadeInUp'}>
                        <p className="nv__section__description text-center">
                            {t('services.description')}
                        </p>
                        <p className="nv__section__description text-center">
                            {t('services.description2')}
                        </p>
                    </Animated> */}
                    <section className="nv__services__container">
                        <div className="nv__services__block">
                            <Animated animation={'animate__fadeInUp'}>
                                <div className="nv__services__block__item">
                                    <div className="nv__services__info">
                                        {/* <h3 className="title">
                                            {'Fostering a Sustainable Future'}
                                        </h3> */}
                                        <p>{t('esg.paragraph1')}</p>

                                        {/* <div className="nv__services__seemore">
                                            <Link to="/logistics">{t('services.button')}</Link>{' '}
                                            <GrNext />
                                        </div> */}
                                    </div>
                                </div>
                            </Animated>
                        </div>

                        <div className="nv__services__block">
                            <Animated animation={'animate__fadeInUp '}>
                                <div className="nv__services__block__item nv__services__block__item--50-1">
                                    <div className="nv__services__info">
                                        {/* <h3 className="title">{t('services.item2.title')}</h3> */}
                                        <p>{t('esg.paragraph2')}</p>

                                        {/* <div className="nv__services__seemore">
                                            <Link to="/retail">{t('services.button')}</Link>{' '}
                                            <GrNext />
                                        </div> */}
                                    </div>
                                </div>
                            </Animated>
                            <Animated animation={'animate__fadeInUp'}>
                                <div className="nv__services__block__item nv__services__block__item--50-2">
                                    <div className="nv__services__info">
                                        {/* <h3 className="title">{t('services.item3.title')}</h3> */}
                                        <p>{t('esg.paragraph3')}</p>

                                        {/* <div className="nv__services__seemore">
                                            <Link to="/technology">{t('services.button')}</Link>{' '}
                                            <GrNext />
                                        </div> */}
                                    </div>
                                </div>
                            </Animated>
                        </div>
                    </section>

                    <section className="nv__services__two__block">
                        <div className="nv__services__agro">
                            <div className="nv__services__block__item">
                                <div className="nv__services__info">
                                    {/* <h3 className="title">{t('services.item4.title')}</h3> */}
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            </main>
        </>
    );
}
