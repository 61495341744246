import React from 'react';
import { Modal } from 'antd';
import { setDisplayComplainsModal, setDisplayModal } from 'reduxStore/slices/contact.slice';
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks';
import ComplainsForm from 'components/generals/forms/complains';
export default function ComplainsModal() {
    const dispatch = useAppDispatch();
    const displayModal = useAppSelector((state) => state.contactSlice.displayComplainsModal);

    return (
        <Modal
            width={992}
            title=""
            open={displayModal}
            centered
            footer={null}
            onCancel={() => {
                dispatch(setDisplayComplainsModal(false));
                dispatch(setDisplayModal(true));
            }}
        >
            <main className="nv__modal">
                <section className="nv__modal__content">
                    <ComplainsForm />
                </section>
            </main>
        </Modal>
    );
}
