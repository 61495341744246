import React from 'react';
import AboutSlide from 'pages/about-us/aboutSlide';
import MisionSection from 'pages/about-us/mision';
import CultureSection from 'pages/about-us/culture';
import ResponsabilitySection from 'pages/about-us/responsability';
import PressSection from 'pages/about-us/press';
import ContactSection from 'pages/home/contact';
import ValuesSection from 'pages/about-us/values';

export default function AboutUsPage() {
    return (
        <>
            <main className="main__page main__page--full">
                <AboutSlide />
                <MisionSection />
                <CultureSection />
                <ValuesSection />
                <ResponsabilitySection />
                {/* <PressSection /> */}
                <ContactSection />
            </main>
        </>
    );
}
