import React from 'react';
import { MdOutlineArrowBackIos, MdArrowForwardIos } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import Animated from 'components/generals/AnimatedComponent';

export default function HarbourPage() {
    const { t } = useTranslation();
    return (
        <main className="affiliates__page affiliates__page--harbor">
            <section className="affiliates__page__section">
                <Animated animation={'animate__fadeInDown'}>
                    <h1>HARBOUR SHIPPING CORPORATION</h1>
                </Animated>
                <Animated animation={'animate__fadeInDown'}>
                    <p>{t('affiliates.harbor.description')}</p>
                    <p>{t('affiliates.harbor.description2')}</p>
                </Animated>
            </section>

            <div className="affiliates__page__footer">
                <a href="https://www.harbour.com.pa/" target="_blank" rel="noreferrer">
                    <div className="btn__action">
                        {t('affiliates.seemore')}
                        <MdArrowForwardIos />
                    </div>
                </a>
            </div>
        </main>
    );
}
