import React from 'react';
import logisticImage2 from 'assets/images/services-pages/logistics/shipping-logistics-2.jpg';
import service1 from 'assets/images/services-pages/logistics/01-PRODUCTIVITY.png';
import service2 from 'assets/images/services-pages/logistics/02-SEGURIDAD.png';
import service3 from 'assets/images/services-pages/logistics/03-COMPLIENCE.png';
import Animated from 'components/generals/AnimatedComponent';
import { useTranslation } from 'react-i18next';

export default function Char() {
    const { t } = useTranslation();
    return (
        <section
            className="nv__service__page__char"
            style={{
                backgroundImage: `url(${logisticImage2})`,
            }}
        >
            <div className="nv__overlay nv__overlay--dark-5">
                <Animated animation={'animate__fadeInUp '}>
                    <section className="nv__section__container">
                        <h1>{t('servicesPage.logistics.characteristics')}</h1>
                        <p>{t('servicesPage.logistics.d.characteristics')}</p>

                        {/* <div className="services__blocks">
                            <div className="services__blocks__items">
                                <div className="services__blocks__image">
                                    <img src={service1} alt="Service 1" />
                                </div>

                                <p className="services__blocks__title">
                                    {t('servicesPage.logistics.service1')}
                                </p>
                            </div>

                            <div className="services__blocks__items">
                                <div className="services__blocks__image">
                                    <img src={service2} alt="Service 1" />
                                </div>

                                <p className="services__blocks__title">
                                    {t('servicesPage.logistics.service2')}
                                </p>
                            </div>

                            <div className="services__blocks__items">
                                <div className="services__blocks__image">
                                    <img src={service3} alt="Service 1" />
                                </div>

                                <p className="services__blocks__title">
                                    {t('servicesPage.logistics.service3')}
                                </p>
                            </div>
                        </div> */}
                    </section>
                </Animated>
            </div>
        </section>
    );
}
