import React from 'react';
import Animated from 'components/generals/AnimatedComponent';

interface ValuesComponentProps {
    title: string;
    info?: string;
    image: string;
}
export default function ValuesComponent({ title, info, image }: ValuesComponentProps) {
    return (
        <>
            <div className="nv__values__item__content">
                <Animated animation={'animate__fadeInUp '}>
                    <div className="nv__values__item" style={{ backgroundImage: `url(${image})` }}>
                        <div className="nv__overlay--dark-3 nv__over">
                            <div className="nv__values__container">
                                <h3>{title}</h3>

                                {
                                    <>
                                        {info && (
                                            <div className="nv__values__info transition">
                                                <p>{info}</p>
                                            </div>
                                        )}
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </Animated>
            </div>
        </>
    );
}
