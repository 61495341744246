import React from 'react';
import { useTranslation } from 'react-i18next';

import DSCI from 'assets/images/home/memberships/DSCI.png';
import AMCHAM from 'assets/images/home/memberships/amcham.png';
import BASC from 'assets/images/home/memberships/BASC.png';
import BlockInfo from 'components/generals/block-info';

const MembershipsItems = [
    {
        title: 'DIGITAL SUPPLY CHAIN INSTITUTE',
        link: 'https://dscinstitute.org/',
        button: 'retail.button',
        imagesrc: DSCI,
        isTranslate: false,
    },
    {
        title: 'AMERICAM CHAMBER OF COMMERCE',
        link: 'https://panamcham.com/es',
        button: 'retail.button',
        imagesrc: AMCHAM,
        isTranslate: false,
    },
    {
        title: 'BASC CERTIFIED',
        link: 'https://www.wbasco.org/en/certification/basc-certification',
        button: 'retail.button',
        imagesrc: BASC,
        isTranslate: false,
    },
];

export default function MembershipsSection() {
    const { t } = useTranslation();
    return (
        <main className="nv__section nv__blocks__info">
            <section className="nv__section__container">
                <h1 className="nv__section__title text-center">{t('memberships.sectionTitle')}</h1>

                <BlockInfo data={MembershipsItems} />
            </section>
        </main>
    );
}
