import { createSlice, PayloadAction } from "@reduxjs/toolkit";


type componentState = {
    displayModal: boolean
    video: string,
    videoSize: string
};

const initialState = {
    displayModal: false,
    video: "",
    videoSize: ""
} as componentState;

export const componentSlice = createSlice({
    name: "componentSlice",
    initialState,
    reducers: {

        setDisplayModal: (state, action: PayloadAction<boolean>) => {
            state.displayModal = action.payload
        },
        setVideo: (state, action: PayloadAction<string>) => {
            state.video = action.payload
        },

        setVideoSize: (state, action: PayloadAction<string>) => {
            state.videoSize = action.payload
        }
    },

});

export const {
    setDisplayModal,
    setVideo,
    setVideoSize
} = componentSlice.actions;
export default componentSlice.reducer;
