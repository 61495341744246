import React, { useEffect } from 'react';
import { useGetPostBySlugQuery } from 'reduxStore/services/post.services';
import { useParams } from 'react-router-dom';
import { GrNext, GrPrevious } from 'react-icons/gr';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
export default function SinglePage() {
    const { t } = useTranslation();
    const { slug } = useParams();
    const { data } = useGetPostBySlugQuery({ slug: slug as string });

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    console.log('data', data);

    return (
        <>
            <main className="main__page main__page--full nv__single">
                <div className="nv__single__header"></div>
                <section className="nv__section__container">
                    <section className="nv__single__container">
                        {data && (
                            <>
                                <div className="nv__single__thumbnail">
                                    <img src={data[0]?.full_image} alt="thumbnail" />
                                </div>

                                <h1 className="nv__single__title">{data[0]?.title.rendered}</h1>

                                <div
                                    className="nv__single__content"
                                    dangerouslySetInnerHTML={{
                                        __html: data[0]?.content.rendered,
                                    }}
                                ></div>

                                <div className="nv__single__nextprev">
                                    {data[0]?.next !== null && (
                                        <>
                                            <Link to={`/post/${data[0]?.next.slug}`}>
                                                <div className="nv__single__nextprev__item">
                                                    <span className="transition item__prev">
                                                        <GrPrevious /> {t('single.previous')}
                                                    </span>
                                                    {/* <h4>{data[0]?.next.title}</h4> */}
                                                </div>
                                            </Link>
                                        </>
                                    )}

                                    {data[0]?.previous !== null && (
                                        <>
                                            <Link to={`/post/${data[0]?.previous.slug}`}>
                                                <div className="nv__single__nextprev__item">
                                                    <span className="transition item__next">
                                                        {t('single.next')} <GrNext />
                                                    </span>

                                                    {/* <h4>{data[0]?.previous.title}</h4> */}
                                                </div>
                                            </Link>
                                        </>
                                    )}

                                    <div className="clearboth"></div>
                                </div>
                            </>
                        )}
                    </section>
                </section>
            </main>
        </>
    );
}
