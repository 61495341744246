import React from 'react';
import { useTranslation } from 'react-i18next';
import PTG from 'assets/images/home/logistics/logo-ptg.png';
import RFS from 'assets/images/home/logistics/RFS.png';
import HB from 'assets/images/home/logistics/hb.png';
import metro from 'assets/images/home/logistics/metro.png';
import PSC from 'assets/images/home/logistics/psc.png';
import PTSS from 'assets/images/home/logistics/ptss.png';
import BlockInfo from 'components/generals/block-info';

const LogisticsItems = [
    {
        title: 'PANAMA TRANSSHIPMENT GROUP',
        link: 'https://ptg.com.pa/',
        button: 'logistic.button',
        imagesrc: PTG,
        isTranslate: false,
    },
    {
        title: 'HARBOUR SHIPPING CORPORATION',
        link: 'https://www.harbour.com.pa/',
        button: 'logistic.button',
        imagesrc: HB,
        isTranslate: false,
    },
    {
        title: 'METRO ESTIBADOR',
        link: '#',
        button: 'logistic.button',
        imagesrc: metro,
        isTranslate: false,
    },

    {
        title: 'PANAMA REEFER SOLUTIONS',
        link: 'https://www.reefer.com.pa/',
        button: 'logistic.button',
        imagesrc: RFS,
        isTranslate: false,
    },

    // {
    //     title: 'PANAMA SHIPPING COMPLIANCE',
    //     link: 'https://psc.com.pa/',
    //     button: 'logistic.button',
    //     imagesrc: PSC,
    //     isTranslate: false,
    // },

    {
        title: 'PTY SHIP SUPPLIERS',
        link: 'https://www.ptyss.com.pa/',
        button: 'logistic.button',
        imagesrc: PTSS,
        isTranslate: false,
    },
];

export default function LogisticSection() {
    const { t } = useTranslation();
    return (
        <main className="nv__section nv__blocks__info">
            <section className="nv__section__container">
                <h1 className="nv__section__title text-center">{t('logistic.sectionTitle')}</h1>
                <BlockInfo data={LogisticsItems} />
            </section>
        </main>
    );
}
