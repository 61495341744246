import { createSlice, PayloadAction } from "@reduxjs/toolkit";


type contactState = {
    data: any,
    displayModal: boolean
    displayMobileMenu: boolean
    displayComplainsModal: boolean
};

const initialState = {
    data: [],
    displayModal: false,
    displayMobileMenu: false,
    displayComplainsModal: false

} as contactState;

export const contactSlice = createSlice({
    name: "contactSlice",
    initialState,
    reducers: {

        setData: (state, action: PayloadAction<number>) => {
            state.data = action.payload
        },

        setDisplayModal: (state, action: PayloadAction<boolean>) => {
            state.displayModal = action.payload
        },

        setDisplayMobileMenu: (state, action: PayloadAction<boolean>) => {
            state.displayMobileMenu = action.payload
        },

        setDisplayComplainsModal: (state, action: PayloadAction<boolean>) => {
            state.displayComplainsModal = action.payload
        }
    },

});

export const {
    setData,
    setDisplayModal,
    setDisplayMobileMenu,
    setDisplayComplainsModal
} = contactSlice.actions;
export default contactSlice.reducer;
