import React from 'react';
import narvalLogo from 'assets/images/logos/logo-white-blue.png';
import bannerImage from 'assets/images/services-pages/agro/agro-1.jpg';
import Animated from 'components/generals/AnimatedComponent';
import { useTranslation } from 'react-i18next';

export default function BannerSmall() {
    const { t } = useTranslation();
    return (
        <section
            className="nv__service__page__banner nv__service__page__banner--small"
            style={{
                backgroundImage: `url(${bannerImage})`,
            }}
        >
            <div className="nv__overlay nv__overlay--dark-5">
                <section className="nv__section__container"></section>
            </div>
        </section>
    );
}
