import React from 'react';
import { useTranslation } from 'react-i18next';
import misionImage from 'assets/images/about-us/image-mision-full.jpg';
import Animated from 'components/generals/AnimatedComponent';

export default function MisionSection() {
    const { t } = useTranslation();
    return (
        <>
            <Animated animation={'animate__fadeInUp '}>
                <main className="nv__section nv__imageInfo nv__imageInfo--padding bg-white-color text-black-color">
                    <section className="nv__section__container">
                        <div className="nv__imageInfo__block nv__imageInfo__block--text">
                            <div className="nv__imageInfo__info">
                                <h4>{t('AboutUS.mission.subtitle')}</h4>
                                <h2>{t('AboutUS.mission.title')}</h2>
                                <p>{t('AboutUS.mission.paragraph1')}</p>
                                {/* <p>{t('AboutUS.mission.paragraph2')}</p> */}
                            </div>
                        </div>
                        <div className="nv__imageInfo__block nv__imageInfo__block--image--square ">
                            <img src={misionImage} alt="Misión" />
                        </div>
                    </section>
                </main>
            </Animated>
        </>
    );
}
