import React from 'react';
import { useTranslation } from 'react-i18next';

import beermark from 'assets/images/home/retail/beermark.png';
import Estela from 'assets/images/home/retail/estela.png';
import panamerican from 'assets/images/home/retail/panamerican.png';
import BlockInfo from 'components/generals/block-info';

const RetailItems = [
    {
        title: 'BEERMARKT',
        link: 'https://beermarkt.com.pa/',
        button: 'retail.button',
        imagesrc: beermark,
        isTranslate: false,
        category: 'retail.retail',
        isTitle: true,
    },

    {
        title: 'PANAMERICAN LOGISTICS INC',
        link: 'https://www.panamerican.com.pa/',
        button: 'retail.button',
        imagesrc: panamerican,
        isTranslate: false,
        category: 'retail.technology',
        isTitle: true,
    },
    {
        title: 'DONNA STELLA',
        link: 'https://agrodonastella.com/',
        button: 'retail.button',
        imagesrc: Estela,
        isTranslate: false,
        category: 'Agro',
        isTitle: true,
    },
];

export default function RestailSection() {
    const { t } = useTranslation();
    return (
        <main className="nv__section nv__blocks__info">
            <section className="nv__section__container">
                <BlockInfo data={RetailItems} />
            </section>
        </main>
    );
}
