import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { api_base } from "constants/API"
import { fields, FIELDS_POST } from "constants/fields"
import { PostObject, loopParams } from 'interfaces/post'
import { SingleObject } from 'interfaces/single'

const PATH = "posts";

export const RSSAPI = createApi({
    reducerPath: "RSSAPI",
    refetchOnFocus: false,

    baseQuery: fetchBaseQuery({
        baseUrl: api_base,
        prepareHeaders: (headers) => {
            headers.set('Content-Type', 'application/json');
            return headers;
        },
        credentials: "include"
    }),

    tagTypes: ['posts'],

    endpoints: (builder) => ({
        getPost: builder.query<PostObject, loopParams>({
            query: (params) => `https://rss.app/feeds/v1.1/FGLGl3kExxfJjgHZ.json`,
            providesTags: ["posts"]
        }),


    }),
});

export const {
    useGetPostQuery,
} = RSSAPI;
