import React from 'react';
import Animated from 'components/generals/AnimatedComponent';
import NV__logo from 'components/generals/logo';
interface SliderItemProps {
    itemSrc: string;
    type: string;
    logoSlogan: boolean;
}
export default function SliderItem({ itemSrc, type, logoSlogan }: SliderItemProps) {
    return (
        <>
            <div className="nv__overlay nv__overlay--dark-4 nv__slider__overlay">
                <Animated animation={'animate__fadeInUp'}>
                    <NV__logo haveSlogan={logoSlogan} />
                </Animated>
            </div>
            {type === 'video' && (
                <>
                    <div className="nv__slider__item">
                        <video autoPlay muted loop>
                            <source src={itemSrc} type="video/mp4" />
                        </video>
                    </div>
                </>
            )}

            {type === 'image' && (
                <>
                    <div
                        className="nv__slider__item"
                        style={{
                            backgroundImage: `url(${itemSrc})`,
                        }}
                    ></div>
                </>
            )}
        </>
    );
}
