import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { api_base } from "constants/API"
import { fields, FIELDS_POST } from "constants/fields"
import { PostObject, loopParams } from 'interfaces/post'
import { SingleObject } from 'interfaces/single'


import config from "config";
const API_URL = "http://localhost:3030"; 
const ACCESS_TOKEN = config.LINKEDIN_ACCESS_TOKEN;
const ORGANIZATION_ID = config.LINKEDIN_ORGANIZATION_ID;

export const linkedinkServices = createApi({
    reducerPath: "linkedinkServices",
    refetchOnFocus: false,

    baseQuery: fetchBaseQuery({
        baseUrl: API_URL,
        prepareHeaders: (headers, {getState}) => {
            headers.set('Content-Type', 'application/json');
            return headers;
        },
        credentials: "include"
    }),

    tagTypes: ['posts'],

    endpoints: (builder) => ({
        getPost: builder.query<any, any>({
            query: (params) => `${API_URL}/linkedin/posts`,
            providesTags: ["posts"],
            
        }),


    }),
});

export const {
    useGetPostQuery,
} = linkedinkServices;
