/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from 'react';
import ActionButton from 'components/ui/buttons/action-button';
import { useTranslation } from 'react-i18next';
import Animated from 'components/generals/AnimatedComponent';
import { useGetPostByCategoryIDQuery } from 'reduxStore/services/post.services';
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks';
import dateTransform from 'helpers/datetransform';
import { Link } from 'react-router-dom';

export default function BlogSection() {
    const { t } = useTranslation();

    const { data } = useGetPostByCategoryIDQuery({
        category_id: 3, //Blog
        per_page: 3,
        page: 1,
        sticky: false,
        offset: 0,
    });

    console.log('data', data);

    return (
        <main className="nv__section nv__blog">
            <section className="nv__section__container">
                <h1 className="nv__section__title text-center">Blog</h1>

                <section className="nv__blog__container">
                    {data?.map((item: any, index: any) => {
                        return (
                            <>
                                <div className="nv__blog__item" key={index}>
                                    <Link to={`/post/${item.slug}`}>
                                        <div className="nv__blog__item__info">
                                            <div className="nv__blog__item__image">
                                                <img src={item.full_image} alt="blog" />
                                            </div>

                                            <div className="nv__blog__item__data">
                                                <span>{item?.categories_list[0].name}</span>
                                                <span className="mb-20">
                                                    {dateTransform(item.date)}
                                                </span>
                                                <h4>{item.title.rendered}</h4>
                                                <p
                                                    dangerouslySetInnerHTML={{
                                                        __html: item.excerpt.rendered,
                                                    }}
                                                ></p>

                                                <span>{t('Blog.readmore')}</span>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </>
                        );
                    })}
                </section>

                <Link to={`/category/blog`}>
                    <ActionButton text={t('Blog.button')} type="rounded clear" />
                </Link>
            </section>
        </main>
    );
}
