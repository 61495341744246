import React from 'react';
import { useTranslation } from 'react-i18next';
import Animated from 'components/generals/AnimatedComponent';
export default function AboutSlide() {
    const { t } = useTranslation();
    return (
        <main className="nv__section nv__about__us__slide" id="about-us">
            <section className="nv__section__container">
                <Animated animation={'animate__fadeInUp '}>
                    <h1>{t('AboutUS.slide.title')}</h1>
                    <p>{t('AboutUS.slide.paragraph')}</p>
                </Animated>
            </section>
        </main>
    );
}
