import React, { useEffect } from 'react';
import MainSlider from 'components/generals/main-slider';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
export default function PreloadPage() {
    const { pathname } = useLocation();
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            <main className="main__page main__page--full nv__preload">
                <MainSlider logoSlogan={false} />

                <section className="nv__preload__footer__menu">
                    <ul className="nv__preload__menu">
                        <li className="menu__item">
                            <Link to="#">{t('preload.logistics')}</Link>
                            <ul className="submenu">
                                <li>
                                    <Link to="/refeer-solutions">PRS</Link>
                                </li>

                                <li>
                                    <Link to="/metro">METS</Link>
                                </li>

                                <li>
                                    <Link to="/ptyss">PTYSS</Link>
                                </li>

                                <li>
                                    <Link to="/harbour">HSC</Link>
                                </li>

                                <li>
                                    <Link to="/ptg">PTG</Link>
                                </li>
                            </ul>
                        </li>
                        <li className="menu__item">
                            <Link to="#">{t('preload.technology')}</Link>
                            <ul className="submenu">
                                <li>
                                    <Link to="/panamerican">PLI</Link>
                                </li>
                            </ul>
                        </li>
                        <li className="menu__item">
                            <Link to="#">{t('preload.agro')}</Link>
                            <ul className="submenu">
                                <li>
                                    <Link to="/donna-stella">DS</Link>
                                </li>
                            </ul>
                        </li>
                        <li className="menu__item">
                            <Link to="#">{t('preload.retail')}</Link>
                            <ul className="submenu">
                                <li>
                                    <Link to="/beermarkt">BM</Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </section>
            </main>
        </>
    );
}
