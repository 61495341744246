import React from 'react';
import { useTranslation } from 'react-i18next';
import Animated from 'components/generals/AnimatedComponent';
interface BlockInfoProps {
    data: any;
}
export default function BlockInfo({ data }: BlockInfoProps) {
    const { t } = useTranslation();
    return (
        <>
            <section className="nv__blocks__info">
                {data.map((item: any) => {
                    return (
                        <div className="nv__blocks__info__item">
                            {item.isTitle && (
                                <>
                                    <h1 className="nv__section__title text-center">
                                        {t(item.category)}
                                    </h1>
                                </>
                            )}
                            <Animated animation={'animate__fadeInUp'}>
                                <div className="nv__blocks__info__item__container">
                                    <a href={item.link} target="_blank">
                                        <div className="logo">
                                            <img src={item.imagesrc} alt="Logo Narval white blue" />
                                        </div>
                                    </a>

                                    <div className="info">
                                        <h4> {item.isTranslate ? t(item.title) : item.title}</h4>
                                        <a href={item.link} target="_blank">
                                            {t(item.button)}
                                        </a>
                                    </div>
                                </div>
                            </Animated>
                        </div>
                    );
                })}
            </section>
        </>
    );
}
