import React, { useState, useEffect } from 'react';
import logo from 'assets/images/logos/logo-white-blue.png';
import { useLocation } from 'react-router-dom';
import config from 'config';

export default function BackToNarval() {
    const location = useLocation();
    const [isFixedInContainer, setIsFixedInContainer] = useState(false);
    const [noScroll, setNoScroll] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const distanceFromBottom =
                document.documentElement.scrollHeight - window.scrollY - window.innerHeight;
            if (distanceFromBottom <= 200) {
                setIsFixedInContainer(true);
            } else {
                setIsFixedInContainer(false);
            }
        };

        const checkIfPageHasScroll = () => {
            if (document.documentElement.scrollHeight <= window.innerHeight) {
                setNoScroll(true);
            } else {
                setNoScroll(false);
            }
        };

        checkIfPageHasScroll();
        window.addEventListener('resize', checkIfPageHasScroll);
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.addEventListener('resize', checkIfPageHasScroll);
        };
    }, [location]);

    return (
        <div className={`nv__back_to_nv  ${isFixedInContainer ? 'fixed-container' : ''}`}>
            <div className={`nv__back_to_nv__logo ${noScroll ? 'no-scroll' : ''}`}>
                <a href={`${config.SITE_URL}`}>
                    <img src={logo} alt="Back to Narval" />
                </a>
            </div>
        </div>
    );
}
