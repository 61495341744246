import React from 'react';
import ValuesComponent from 'components/generals/values';
import etica from 'assets/images/values/values-etica.jpg';
import moral from 'assets/images/values/value-moral.jpg';
import voluntad from 'assets/images/values/value-voluntad.jpg';
import honestidad from 'assets/images/values/value-honestidad.jpg';
import valores from 'assets/images/values/value-main.png';
import transparencia from 'assets/images/values/value-transparencia.jpg';
import { useTranslation } from 'react-i18next';

export default function ValuesItems() {
    const { t } = useTranslation();

    return (
        <>
            <ValuesComponent
                image={etica}
                title={`${t('values.ethics')}`}
                info={`${t('values.d.ethics')}`}
            />

            <ValuesComponent
                image={moral}
                title={`${t('values.moral')}`}
                info={`${t('values.d.moral')}`}
            />

            <ValuesComponent
                image={voluntad}
                title={`${t('values.will')}`}
                info={`${t('values.d.will')}`}
            />

            <ValuesComponent
                image={honestidad}
                title={`${t('values.honesty')}`}
                info={`${t('values.d.honesty')}`}
            />

            <ValuesComponent
                image={valores}
                title={`${t('values.respect')}`}
                info={`${t('values.d.respect')}`}
            />

            <ValuesComponent
                image={transparencia}
                title={`${t('values.transparency')}`}
                info={`${t('values.d.transparency')}`}
            />
        </>
    );
}
