import React from 'react';

import { FaHandshake, FaLinkedin } from 'react-icons/fa';
import { MdOndemandVideo } from 'react-icons/md';
import { BiRss } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from 'reduxStore/hooks';
import ESG from '../../../assets/images/services-menu/5-ESG.png';
export default function AliancesMeu() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    return (
        <section className="nv__alliances__menu">
            <div className="nv__alliances__menu__item">
                <Tooltip placement="rightBottom" title={t('alliancesMenu.alliances')}>
                    <Link to="/alliances">
                        <FaHandshake />
                    </Link>
                </Tooltip>
            </div>

            {/* <div
                className="nv__alliances__menu__item"
                onClick={() => {
                    dispatch(setDisplayModal(true));
                    dispatch(setVideoSize('nv__video__full'));
                }}
            >
                <Tooltip placement="rightBottom" title={t('alliancesMenu.video')}>
                    <MdOndemandVideo />
                </Tooltip>
            </div> */}

            <div className="nv__alliances__menu__item">
                <Tooltip placement="rightBottom" title={t('alliancesMenu.blog')}>
                    <Link to="/blog">
                        <BiRss />
                    </Link>
                </Tooltip>
            </div>

            <div className="nv__alliances__menu__item">
                <Tooltip placement="rightBottom" title={'Linkedin'}>
                    <Link to="https://www.linkedin.com/company/narvalholding/" target="_blank">
                        <FaLinkedin />
                    </Link>
                </Tooltip>
            </div>

            <div className="nv__alliances__menu__item">
                <Tooltip placement="rightBottom" title={'ESG'}>
                    <Link to="/esg">
                        <img src={ESG} alt="ESG" />
                    </Link>
                </Tooltip>
            </div>
        </section>
    );
}
