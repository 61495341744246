import React from 'react';
import { useTranslation } from 'react-i18next';
import cultureImage from 'assets/images/about-us/culture-image.png';
import Animated from 'components/generals/AnimatedComponent';

export default function CultureSection() {
    const { t } = useTranslation();
    return (
        <>
            <Animated animation={'animate__fadeInUp '}>
                <main className="nv__section nv__imageInfo nv__imageInfo--padding bg-strong-blue-color text-white-color">
                    <section className="nv__section__container">
                        <div className="nv__imageInfo__block nv__imageInfo__block--text">
                            <div className="nv__imageInfo__info ">
                                <h4>{t('AboutUS.culture.subtitle')}</h4>
                                <h2>{t('AboutUS.culture.title')}</h2>
                                <p>{t('AboutUS.culture.paragraph1')}</p>
                                <p>{t('AboutUS.culture.paragraph2')}</p>
                            </div>
                        </div>
                        <div className="nv__imageInfo__block nv__imageInfo__block--image--square ">
                            <img src={cultureImage} alt="culure" />
                        </div>
                    </section>
                </main>
            </Animated>
        </>
    );
}
