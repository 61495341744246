import React from 'react';
import RetailSection from 'pages/home/retail';
import LogisticSection from 'pages/home/logistic';

export default function CompaniesPage() {
    return (
        <section className="companies__page">
            <RetailSection />
            <LogisticSection />
        </section>
    );
}
