/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetPostByCategoryIDQuery } from 'reduxStore/services/post.services';
import dateTransform from 'helpers/datetransform';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

export default function CategoryPage() {
    const { t } = useTranslation();
    const { slug } = useParams();
    let category_id = 0;
    if (slug === 'blog') {
        category_id = 3;
    }

    if (slug === 'prensa') {
        category_id = 2;
    }
    if (slug === 'noticias') {
        category_id = 4;
    }

    const { data } = useGetPostByCategoryIDQuery({
        category_id: category_id, //Blog
        per_page: 20,
        page: 1,
        sticky: false,
        offset: 0,
    });

    console.log('data', data);

    return (
        <>
            <main className="nv__section nv__blog">
                <div className="nv__blog__header"></div>
                <section className="nv__section__container">
                    <h1 className="nv__section__title text-center">{slug}</h1>

                    <section className="nv__blog__container">
                        {data?.map((item: any, index: any) => {
                            return (
                                <>
                                    <div className="nv__blog__item" key={index}>
                                        <Link to={`/post/${item.slug}`}>
                                            <div className="nv__blog__item__info">
                                                <div className="nv__blog__item__image">
                                                    <img src={item.full_image} alt="blog" />
                                                </div>

                                                <div className="nv__blog__item__data">
                                                    <span>{item?.categories_list[0].name}</span>
                                                    <span className="mb-20">
                                                        {dateTransform(item.date)}
                                                    </span>
                                                    <h4>{item.title.rendered}</h4>
                                                    <p
                                                        dangerouslySetInnerHTML={{
                                                            __html: item.excerpt.rendered,
                                                        }}
                                                    ></p>

                                                    <span>{t('Blog.readmore')}</span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </>
                            );
                        })}
                    </section>
                </section>
            </main>
        </>
    );
}
