import React from 'react';
import { MdOutlineArrowBackIos, MdArrowForwardIos } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import Animated from 'components/generals/AnimatedComponent';

export default function BermarktPage() {
    const { t } = useTranslation();
    return (
        <main className="affiliates__page affiliates__page--beermark">
            <section className="affiliates__page__section">
                <Animated animation={'animate__fadeInDown'}>
                    <h1>BEERMARKT</h1>
                </Animated>
                <Animated animation={'animate__fadeInDown'}>
                    <p>{t('affiliates.beermark.description')}</p>
                </Animated>
            </section>

            <div className="affiliates__page__footer">
                <a href="https://beermarkt.com.pa/" target="_blank" rel="noreferrer">
                    <div className="btn__action">
                        {t('affiliates.seemore')}
                        <MdArrowForwardIos />
                    </div>
                </a>
            </div>
        </main>
    );
}
