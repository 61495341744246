import React from 'react';
import { useTranslation } from 'react-i18next';
import respImage from 'assets/images/about-us/utp.png';
import Animated from 'components/generals/AnimatedComponent';

export default function ResponsabilitySection() {
    const { t } = useTranslation();
    return (
        <main className="nv__section  nv__section--with-padding nv__imageInfo bg-strong-blue-color text-white-color">
            <Animated animation={'animate__fadeInUp '}>
                <section className="nv__section__container">
                    <div className="nv__imageInfo__block nv__imageInfo__block--text">
                        <div className="nv__imageInfo__info">
                            <h4>{t('AboutUS.responsability.subtitle')}</h4>
                            <h2>{t('AboutUS.responsability.title')}</h2>
                            <p>{t('AboutUS.responsability.paragraph1')}</p>

                            <p>{t('AboutUS.responsability.paragraph2')}</p>

                            <p>{t('AboutUS.responsability.paragraph3')}</p>

                            <ul>
                                <li>
                                    <strong> {t('AboutUS.responsability.dot1')}</strong>
                                </li>
                                <li>
                                    <strong> {t('AboutUS.responsability.dot2')}</strong>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div
                        className="nv__imageInfo__block nv__imageInfo__block--image"
                        style={{ backgroundImage: `url(${respImage})` }}
                    ></div>

                    <div className="nv__imageInfo__responsability">
                        <img src={respImage} alt="UTP" />
                    </div>
                </section>
            </Animated>
        </main>
    );
}
