import React, { ReactElement } from 'react';

interface ActionButtonProps {
    text: string;
    onClick?: () => void;
    icon?: ReactElement;
    type: string;
}
export default function ActionButton({ text, onClick, icon, type }: ActionButtonProps) {
    return (
        <>
            {type === 'rounded' && (
                <button className="nv__button nv__button--rounded transition" onClick={onClick}>
                    {text} {icon}
                </button>
            )}

            {type === 'rounded color' && (
                <button
                    className="nv__button nv__button--rounded-color transition"
                    onClick={onClick}
                >
                    {text} {icon}
                </button>
            )}

            {type === 'rounded clear' && (
                <button
                    className="nv__button nv__button--rounded--clear transition"
                    onClick={onClick}
                >
                    {text} {icon}
                </button>
            )}
        </>
    );
}
