import React from 'react';
import narvalLogo from 'assets/images/logos/logo-white-blue.png';
import bannerImage from 'assets/images/services-pages/logistics/shipping-logistics-1.jpg';
import Animated from 'components/generals/AnimatedComponent';
import { useTranslation } from 'react-i18next';

export default function Banner() {
    const { t } = useTranslation();
    return (
        <section
            className="nv__service__page__banner"
            style={{
                backgroundImage: `url(${bannerImage})`,
            }}
        >
            <div className="nv__overlay nv__overlay--dark-5">
                <section className="nv__section__container">
                    <Animated animation={'animate__fadeInUp '}>
                        {/* <div className="nv__service__page__banner__logo">
                            <img src={narvalLogo} alt="Narval logo" />
                        </div> */}
                        <h1>{t('servicesPage.logistics.title')}</h1>
                    </Animated>
                </section>
            </div>
        </section>
    );
}
