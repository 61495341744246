import React from 'react';
import { MdOutlineArrowBackIos, MdArrowForwardIos } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import Animated from 'components/generals/AnimatedComponent';

export default function PTYSSPage() {
    const { t } = useTranslation();
    return (
        <main className="affiliates__page affiliates__page--ptyss">
            <section className="affiliates__page__section">
                <Animated animation={'animate__fadeInDown'}>
                    <h1>PTYSS</h1>
                </Animated>
                <Animated animation={'animate__fadeInDown'}>
                    <p>{t('affiliates.ptyss.description')}</p>
                </Animated>
            </section>

            <div className="affiliates__page__footer">
                <a
                    href="https://www.ptyss.com.pa/en/ptyss-english-version/#about"
                    target="_blank"
                    rel="noreferrer"
                >
                    <div className="btn__action">
                        {t('affiliates.seemore')}
                        <MdArrowForwardIos />
                    </div>
                </a>
            </div>
        </main>
    );
}
