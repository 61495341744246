import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Animated from 'components/generals/AnimatedComponent';

export default function TimeLineSection() {
    const location = useLocation();
    const { t } = useTranslation();
    useEffect(() => {
        const hash = location.hash;
        if (hash) {
            setTimeout(() => {
                const element = document.querySelector(hash);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }, 0);
        }
    }, [location]);
    return (
        <>
            <Animated animation={'animate__fadeIn '}>
                <main className="nv__section nv__timeline" id="timeline">
                    <section className="nv__section__container">
                        <h1 className="nv__section__title text-center">
                            {t('history.sectionTitle')}
                        </h1>

                        <div className="nv__timeline__antesala">
                            <p>{t('history.anteroom1')}</p>
                            <p>{t('history.anteroom2')}</p>
                            <p>{t('history.anteroom3')}</p>
                        </div>

                        <section className="nv__timeline__vertical">
                            <div className="nv__timeline__vertical__item">
                                <div className="dots"></div>
                                <div className="line line--small"></div>

                                <h3>2010</h3>
                                <p>{t('history.paragraph1')}</p>
                            </div>
                            <div className="line line--small"></div>

                            <div className="nv__timeline__vertical__item">
                                <div className="dots"></div>
                                <div className="line line--small"></div>
                                <h3>2012</h3>
                                <p>{t('history.paragraph2')}</p>
                            </div>
                            <div className="line line--small"></div>

                            <div className="nv__timeline__vertical__item">
                                <div className="dots"></div>
                                <div className="line line--small"></div>
                                <h3>2013</h3>
                                <p>{t('history.paragraph3')}</p>
                            </div>
                            <div className="line line--small"></div>

                            <div className="nv__timeline__vertical__item">
                                <div className="dots"></div>
                                <div className="line line--small"></div>
                                <h3>2017</h3>
                                <p>{t('history.paragraph4')}</p>
                            </div>
                            <div className="line line--small"></div>

                            <div className="nv__timeline__vertical__item">
                                <div className="dots"></div>
                                <div className="line line--small"></div>
                                <h3>2019</h3>
                                <p>{t('history.paragraph5')}</p>
                            </div>
                            <div className="line line--small"></div>

                            <div className="nv__timeline__vertical__item">
                                <div className="dots"></div>
                                <div className="line line--small"></div>
                                <h3>2020</h3>
                                <p>{t('history.paragraph6')}</p>
                            </div>
                            <div className="line line--small"></div>

                            <div className="nv__timeline__vertical__item">
                                <div className="dots"></div>
                                <div className="line line--small"></div>
                                <h3>2024</h3>
                                <p>{t('history.paragraph7')}</p>
                            </div>
                        </section>
                    </section>

                    <section className="nv__timeline__base">
                        <div className="nv__timeline__item">
                            <h3>2010</h3>
                            <p>{t('history.paragraph1')}</p>
                            <div className="nv__timeline__dots"></div>
                        </div>

                        <div className="nv__timeline__item">
                            <h3>2012</h3>
                            <p>{t('history.paragraph2')}</p>
                            <div className="nv__timeline__dots"></div>
                        </div>
                        <div className="nv__timeline__item">
                            <h3>2013</h3>
                            <p>{t('history.paragraph3')}</p>
                            <div className="nv__timeline__dots"></div>
                        </div>
                        <div className="nv__timeline__item">
                            <h3>2017</h3>
                            <p>{t('history.paragraph4')}</p>
                            <div className="nv__timeline__dots"></div>
                        </div>

                        <div className="nv__timeline__item">
                            <h3>2019</h3>
                            <p>{t('history.paragraph5')}</p>
                            <div className="nv__timeline__dots"></div>
                        </div>
                        <div className="nv__timeline__item">
                            <h3>2020</h3>
                            <p>{t('history.paragraph6')}</p>
                            <div className="nv__timeline__dots"></div>
                        </div>

                        <div className="nv__timeline__item">
                            <h3>2024</h3>
                            <p>{t('history.paragraph7')}</p>
                            <div className="nv__timeline__dots"></div>
                        </div>
                    </section>
                </main>
            </Animated>
        </>
    );
}
