import React from 'react';
import 'styles/settings/fonts.css';
import 'styles/styles.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainHeader from 'components/ui/header';
import HomePage from 'pages/home';
import AboutUsPage from 'pages/about-us';
import SinglePage from 'pages/single';
import CategoryPage from 'pages/category';
import ServicesPage from 'pages/services';
import HistoryPage from 'pages/history';
import PreloadPage from 'pages/preload';
import PTGPage from 'pages/affiliates/ptg';
import BlogPage from 'pages/blog';
import AlliancesPage from 'pages/alliances';
import PanamericanPage from 'pages/affiliates/panamerican';
import DonnaStellaPage from 'pages/affiliates/donnastella';
import HarborPage from 'pages/affiliates/harbor';
import PTYSSPage from 'pages/affiliates/ptyss';
import BeermarktPage from 'pages/affiliates/beermark';
import ReffSolutionsPage from 'pages/affiliates/reffsolutions';
import MetroPage from 'pages/affiliates/metro';
import ESGPage from 'pages/esg';
import ContactModal from 'components/generals/modal/contact';
import MenuMovil from 'components/generals/menu-movil';
import MainFooter from 'components/ui/footer';
import AlliancesMenu from 'components/generals/aliances-menu';
import PlayerModal from 'components/generals/modal/player';
import ComplainsModal from 'components/generals/modal/complains';
import { useLocation } from 'react-router-dom';
import BackToNarval from 'components/generals/backToNarval';

import 'animate.css';
import './i18n';
function App() {
    const { pathname } = useLocation();
    console.log(pathname);
    return (
        <>
            <MainHeader />
            <Routes>
                <Route path="/" element={<PreloadPage />} />
                <Route path="/home" element={<HomePage />} />
                <Route path="/about-us" element={<AboutUsPage />} />
                <Route path="/services" element={<ServicesPage />} />
                <Route path="/history" element={<HistoryPage />} />
                <Route path="/post/:slug" element={<SinglePage />} />
                <Route path="/category/:slug" element={<CategoryPage />} />
                <Route path="/ptg" element={<PTGPage />} />
                <Route path="/blog" element={<BlogPage />} />
                <Route path="/alliances" element={<AlliancesPage />} />
                <Route path="/panamerican" element={<PanamericanPage />} />
                <Route path="/metro" element={<MetroPage />} />
                <Route path="/donna-stella" element={<DonnaStellaPage />} />
                <Route path="/harbour" element={<HarborPage />} />
                <Route path="/ptyss" element={<PTYSSPage />} />
                <Route path="/beermarkt" element={<BeermarktPage />} />
                <Route path="/esg" element={<ESGPage />} />
                <Route path="/refeer-solutions" element={<ReffSolutionsPage />} />
            </Routes>
            <MenuMovil />
            <AlliancesMenu />
            <PlayerModal />
            <ContactModal />
            <ComplainsModal />

            {pathname !== '/' &&
                pathname !== '/alliances' &&
                pathname !== '/services' &&
                pathname !== '/history' &&
                pathname !== '/esg' &&
                pathname !== '/about-us' && <BackToNarval />}
            {pathname !== '/' &&
                pathname !== '/ptg' &&
                pathname !== '/panamerican' &&
                pathname !== '/metro' &&
                pathname !== '/donna-stella' &&
                pathname !== '/harbour' &&
                pathname !== '/ptyss' &&
                pathname !== '/beermarkt' &&
                pathname !== '/refeer-solutions' && (
                    <>
                        <MainFooter />
                    </>
                )}
        </>
    );
}

export default App;
