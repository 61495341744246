import React from 'react';
import ValuesComponent from 'components/generals/values';
import confianza from 'assets/images/values/qualities-confianza.jpg';
import optimismo from 'assets/images/values/qualities-optimismo.jpg';
import emprendedor from 'assets/images/values/qualities-emprendedor.jpg';
import decisivo from 'assets/images/values/qualities-decisivo.jpg';
import cualidades from 'assets/images/values/qualities-main.jpg';
import responsable from 'assets/images/values/qualities-responsable.jpg';
import { useTranslation } from 'react-i18next';

export default function QualitiesItems() {
    const { t } = useTranslation();
    return (
        <>
            <ValuesComponent
                image={confianza}
                title={`${t('values.trust')}`}
                info={`${t('values.d.trust')}`}
            />

            <ValuesComponent
                image={optimismo}
                title={`${t('values.optimism')}`}
                info={`${t('values.d.optimism')}`}
            />

            <ValuesComponent
                image={emprendedor}
                title={`${t('values.entrepreneur')}`}
                info={`${t('values.d.entrepreneur')}`}
            />

            <ValuesComponent
                image={decisivo}
                title={`${t('values.decisive')}`}
                info={`${t('values.d.decisive')}`}
            />

            <ValuesComponent
                image={cualidades}
                title={`${t('values.resilience')}`}
                info={`${t('values.d.resilience')}`}
            />

            <ValuesComponent
                image={responsable}
                title={`${t('values.responsible')}`}
                info={`${t('values.d.responsible')}`}
            />
        </>
    );
}
