import React, { useEffect, useState } from 'react';

import { useGetPostQuery } from 'reduxStore/services/linkedin.services';
import config from 'config';
export default function BlogRSSs() {
    const { data, error, isLoading } = useGetPostQuery({});

    console.log('data LINKEDIN', data);

    return (
        <>
            <main className="nv__section nv__blog">
                <div className="nv__blog__header"></div>
                <section className="nv__section__container">
                    <h1 className="nv__section__title text-center">BLOG</h1>

                    <section className="nv__blog__container">
                        loading...
                        {/* {data?.details?.items.map(function (item: any) {
                            return <div className="nv__blog__item">{item?.commentary}</div>;
                        })} */}
                    </section>
                </section>
            </main>
        </>
    );
}
