
import { message } from 'antd';
import * as yup from 'yup';
export const contactFormSchema = yup
    .object({

        name: yup
            .string()
            .required('This field is required'),
        company: yup
            .string()
            .required('This field is required'),    
        
        email: yup
            .string()
            .required('This field is required')
            .email('You must enter a valid email'),

        message: yup
            .string()
            .required('This field is required'),
    })
    .required();
