import React from 'react';
import serviceIcon1 from 'assets/images/services-menu/1-logistics.png';
import serviceIcon2 from 'assets/images/services-menu/2-franchise.png';
import serviceIcon3 from 'assets/images/services-menu/3-tecnology.png';
import serviceIcon4 from 'assets/images/services-menu/4-agro.png';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

export default function ServiceMenu() {
    const { t } = useTranslation();

    return (
        <section className="nv__services__menu">
            <div className="nv__services__menu__item">
                <Tooltip placement="rightBottom" title={t('services.item1.title')}>
                    <Link to="/logistics">
                        <img src={serviceIcon1} alt="Logistics icon" />
                    </Link>
                </Tooltip>
            </div>

            <div className="nv__services__menu__item">
                <Tooltip placement="rightBottom" title={t('services.item2.title')}>
                    <Link to="/retail">
                        <img src={serviceIcon2} alt="Franchise icon" />
                    </Link>
                </Tooltip>
            </div>

            <div className="nv__services__menu__item">
                <Tooltip placement="rightBottom" title={t('services.item3.title')}>
                    <Link to="/technology">
                        <img src={serviceIcon3} alt="Tecnology icon" />
                    </Link>
                </Tooltip>
            </div>

            <div className="nv__services__menu__item">
                <Tooltip placement="rightBottom" title={t('services.item4.title')}>
                    <Link to="/agro">
                        <img src={serviceIcon4} alt="Agro icon" />
                    </Link>
                </Tooltip>
            </div>
        </section>
    );
}
