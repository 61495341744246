import React from 'react';
import footerLogo from 'assets/images/logos/logo-white-blue.png';
import { useTranslation } from 'react-i18next';

export default function MainFooter() {
    const { t } = useTranslation();
    return (
        <footer className="nv__footer">
            <section className="nv__section__container">
                <div className="nv__footer__block nv__footer__block--big">
                    <div className="nv__footer__block__item">
                        <p>
                            Calle 74 | San Francisco <br />
                            PH Midtown - Suite 1405
                            <br />
                            {t('generals.address')}
                        </p>
                    </div>

                    {/* <div className="nv__footer__block__item">
                        <p>
                            San Francisco <br />
                            PH Midtown - 1405
                        </p>
                    </div> */}

                    <div className="nv__footer__block__item">
                        <p>
                            info@narval.com
                            <br />
                            +507 277 8888
                        </p>
                    </div>
                    <div className="nv__footer__block__item nv__footer__block__item--web">
                        <div className="nv__footer__logo">
                            <a href="#">
                                <img src={footerLogo} alt="Logo Narval white blue" />
                            </a>
                        </div>
                    </div>
                </div>

                <div className="nv__footer__logo nv__footer__logo--mobile">
                    <a href="#">
                        <img src={footerLogo} alt="Logo Narval white blue" />
                    </a>
                </div>
            </section>
        </footer>
    );
}
