import React, { useEffect } from 'react';
import MainSlider from 'components/generals/main-slider';
import AboutUsSection from 'pages/home/about-us';
import CompromiseSection from 'pages/home/compromise';
import ServicesSection from 'pages/home/services';
import LogisticSection from 'pages/home/logistic';
import RetailSection from 'pages/home/retail';
import MembershipsSection from 'pages/home/memberships';
import BlogSection from 'components/generals/blog';
import ContactSection from 'pages/home/contact';
import { useLocation } from 'react-router-dom';
import ServiceMenu from 'components/generals/services-menu';

export default function HomePage() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            <main className="main__page main__page--full nv__home__page">
                <MainSlider logoSlogan={true} />
                <AboutUsSection />
                <CompromiseSection />
                <ServicesSection />
                <LogisticSection />
                <RetailSection />
                <MembershipsSection />
                <BlogSection />
                <ContactSection />
                <ServiceMenu />
            </main>
        </>
    );
}
