import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Animated from 'components/generals/AnimatedComponent';

export default function AboutUsSection() {
    const { t } = useTranslation();
    const location = useLocation();

    useEffect(() => {
        const hash = location.hash;
        if (hash) {
            setTimeout(() => {
                const element = document.querySelector(hash);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }, 0);
        }
    }, [location]);

    return (
        <main className="nv__section nv__about__us">
            <section className="nv__section__container">
                <h1 className="nv__section__title text-center">{t('about.sectionTitle')}</h1>

                <section className="image__container">
                    <div className="image__container__block  image__container__block__left">
                        <Animated animation={'animate__fadeInUp  wrapper wrapper--full'}>
                            <div className="image__container__block__left__image"></div>
                        </Animated>
                    </div>

                    <div className="image__container__block  image__container__block__right">
                        <Animated animation={'animate__fadeInUp wrapper wrapper--full'}>
                            <div className="image__container__block__right__image"></div>
                        </Animated>
                    </div>
                </section>

                <section className="text__container">
                    <div className="text__container__text  text__container__text--big">
                        <div className="main__title">
                            <Animated animation={'animate__fadeInUp'}>
                                <h2>{t('about.title')}</h2>
                            </Animated>
                        </div>

                        <div className="main__info">
                            <Animated animation={'animate__fadeInUp'}>
                                <h4>{t('about.mission.title')}</h4>
                                <p>{t('about.mission.paragraph1')}</p>

                                <p>{t('about.mission.paragraph2')}</p>
                            </Animated>
                        </div>
                    </div>

                    <div className="text__container__text  text__container__text--small">
                        <Animated animation={'animate__fadeInUp'}>
                            <h4>{t('about.proposal.title')}</h4>
                            <p>{t('about.proposal.paragraph1')}</p>
                        </Animated>
                    </div>
                </section>
            </section>
        </main>
    );
}
