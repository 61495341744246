import React, { useState, useEffect } from 'react';
import ValuesItems from 'pages/about-us/values/valiesItems';
import QualitiesItems from 'pages/about-us/values/qualitiesItems';
import { useTranslation } from 'react-i18next';
import Animated from 'components/generals/AnimatedComponent';

export default function ValuesSection() {
    const { t } = useTranslation();
    const [active, setActive] = useState(false);

    const handleClick = () => {
        setActive(!active);
    };

    return (
        <>
            <main className="nv__section nv__values bg-strong-blue-color">
                <Animated animation={'animate__fadeInUp '}>
                    <div className="nv__values__selector">
                        <div
                            className={`${active ? 'nv__values__selector__options nv__values__selector__options' : 'nv__values__selector__options nv__values__selector__options--active'} `}
                            onClick={() => {
                                setActive(!active);
                            }}
                        >
                            {`${t('values.m.values')}`}
                        </div>
                        <div
                            className={`${active ? 'nv__values__selector__options nv__values__selector__options--active' : 'nv__values__selector__options nv__values__selector__options'} `}
                            onClick={() => {
                                setActive(!active);
                            }}
                        >
                            {`${t('values.m.qualitues')}`}
                        </div>
                    </div>
                </Animated>
                <section className="nv__section__container">
                    {active ? <QualitiesItems /> : <ValuesItems />}
                </section>
            </main>
        </>
    );
}
