import React from 'react';
import { useTranslation } from 'react-i18next';
import compromiseImg from 'assets/images/home/compromise/compromise-image-1-full.jpg';
import Animated from 'components/generals/AnimatedComponent';

export default function CompromiseSection() {
    const { t } = useTranslation();
    return (
        <main className="nv__section nv__imageInfo bg-strong-blue-color text-white-color">
            <section className="nv__section__container">
                <div className="nv__imageInfo__block nv__imageInfo__block--text">
                    <div className="nv__imageInfo__info">
                        <Animated animation={'animate__fadeInUp'}>
                            <h4>{t('compromise.title')}</h4>
                            <p>{t('compromise.paragraph1')}</p>

                            <p>{t('compromise.paragraph2')}</p>

                            <p>{t('compromise.paragraph3')}</p>
                        </Animated>
                    </div>
                </div>

                <div className="nv__imageInfo__picture">
                    <Animated animation={'animate__fadeInUp wrapper wrapper--full'}>
                        <div
                            className="nv__imageInfo__picture__image"
                            style={{ backgroundImage: `url(${compromiseImg})` }}
                        ></div>
                    </Animated>
                </div>
            </section>
        </main>
    );
}
