import React from 'react';
import { useTranslation } from 'react-i18next';
import Future from 'assets/images/about-us/future.jpg';
import Animated from 'components/generals/AnimatedComponent';

export default function FutureSection() {
    const { t } = useTranslation();
    return (
        <>
            <main className="nv__section nv__imageInfo nv__imageInfo--padding bg-strong-blue-color text-white-color">
                <section className="nv__section__container">
                    <div className="nv__imageInfo__block nv__imageInfo__block--image nv__imageInfo__block--full ">
                        <div className="nv__imageInfo__timon nv__imageInfo__future--web"></div>
                        <div className="nv__imageInfo__timon nv__imageInfo__future--mobile">
                            <img src={Future} alt="Future" />
                        </div>
                    </div>
                    <div className="nv__imageInfo__block nv__imageInfo__block--text">
                        <div className="nv__imageInfo__info nv__imageInfo__info">
                            <h2 className="mb-30">{t('AboutUS.future.title')}</h2>

                            <p>{t('AboutUS.future.paragraph')}</p>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}
