import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Info() {
    const { t } = useTranslation();
    return (
        <section className="nv__service__page__info">
            <section className="nv__section__container">
                {/* <h1>{t('servicesPage.logistics.titleNew')}</h1>
                <div className="nv__service__page__info__line"></div> */}
                <p>{t('servicesPage.logistics.description')}</p>
            </section>
        </section>
    );
}
