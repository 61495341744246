 

const dateTransform = (isoDate: string) => {

    const date = new Date(isoDate);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Los meses empiezan en 0, así que se suma 1
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    // Formatear la fecha como desees
    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
}

export default dateTransform;