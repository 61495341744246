import React from 'react';
import { Modal } from 'antd';
import { setDisplayModal as setDisplayModalPlayer } from 'reduxStore/slices/component.slide';
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks';
import VideoComponent from 'components/generals/video';
export default function PlayerModal() {
    const dispatch = useAppDispatch();
    const displayModal = useAppSelector((state) => state.componentSlice.displayModal);

    return (
        <Modal
            width={992}
            title=""
            open={displayModal}
            centered
            footer={null}
            onCancel={() => {
                dispatch(setDisplayModalPlayer(false));
            }}
        >
            <main className="nv__modal">
                <section className="nv__modal__content">
                    <VideoComponent />
                </section>
            </main>
        </Modal>
    );
}
