import React from 'react';
import TimeLineSection from 'components/generals/timeline';
import FutureSection from 'pages/about-us/future';

export default function HistoryPage() {
    return (
        <>
            <TimeLineSection />
            <FutureSection />
        </>
    );
}
